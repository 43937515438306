import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Home = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const uniqueID = searchParams.get("uniqueID");
    const groupID = searchParams.get("groupID");

    useEffect(() => {
        if (!groupID) {
            navigate('/about', { replace: true })
            return
        }

        const params = new URLSearchParams({
            groupID: groupID,
            uniqueID: uniqueID,
        }).toString();

        navigate(`/scan?${params}`, { replace: true })

    }, [groupID, uniqueID, navigate]);

}

export default Home;