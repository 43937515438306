import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScanPage from './ScanPage';
import QrStatistics from './QrStatistics';
import About from './About';
import Home from './Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} ></Route>
        <Route path='/scan' element={<ScanPage />} ></Route>
        <Route path='/stats' element={<QrStatistics />} ></Route>
        <Route path='/about' element={<About />} ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
