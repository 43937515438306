import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import loadingWorld from "./lottie/worldLoading.json"
import errorAnimation404 from "./lottie/animationError-404.json"
import Lottie from 'lottie-react';
import { firebaseConfig } from "./Firebase";

const ScanPage = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const uniqueID = searchParams.get("uniqueID");
    const groupID = searchParams.get("groupID");

    initializeApp(firebaseConfig);

    useEffect(() => {
        if (!groupID) {
            navigate('/about', { replace: true })
            return
        }

        const fetchAd = async () => {
            try {
                console.log(`fetch called`)
                const response = await fetch(`https://europe-west6-spotlytic.cloudfunctions.net/handleScan?uniqueID=${uniqueID}&groupID=${groupID}`);
                if (!response.ok) {
                    setError("Error fetching ad");
                    setLoading(false);
                    return;
                }

                console.log(`fetch response OK`)

                const { adURL } = await response.json();

                setLoading(false);
                window.location.href = adURL;
            } catch (error) {
                console.log(`fetch response ERROR`)
                setError(error.message);
                setLoading(false);
            }
        };

        fetchAd();
    }, [groupID, uniqueID, navigate]);

    if (loading) {
        return loadingAnimation("Loading...");
    }

    if (error) {
        return errorAnimation();
    }

    return loadingAnimation("Redirecting...");
};

const loadingAnimation = (message) => {
    return (
        <div className="loadingBackground">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-10 text-center align-items-center">
                        <Lottie animationData={loadingWorld} loop={true} />
                        <h5 className="mt-4">{message}</h5>
                    </div>
                </div>
            </div>
        </div>

    )
}

const errorAnimation = () => {
    return (
        <div className="loadingBackground">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-10 text-center align-items-center">
                        <Lottie animationData={errorAnimation404} loop={true} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ScanPage;
